import { useTransition, animated } from 'react-spring'

import { useTranslate } from '/machinery/I18n'
import { ButtonCircularGhost } from '/features/buildingBlocks/Button'
import { LinkButtonCircularPrimary } from '/features/buildingBlocks/Link'
import { Icon } from '/features/buildingBlocks/Icon'

import shareIcon from '/images/icons/share.raw.svg'
import crossIcon from '/images/icons/cross.raw.svg'
import facebookIcon from '/images/icons/facebook.raw.svg'
import twitterIcon from '/images/icons/twitter.raw.svg'
import linkedinIcon from '/images/icons/linkedIn.raw.svg'
import whatsappIcon from '/images/icons/whatsapp.raw.svg'
import emailIcon from '/images/icons/mail.raw.svg'

import styles from './Share.css'

export function Share({ title, url, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const [expanded, setExpanded] = React.useState(false)
  const encodedUrl = encodeURIComponent(url)
  const encodedTitle = encodeURIComponent(title)

  const shareButtons = React.useMemo(
    () => [
      {
        dataX: 'share-via-facebook',
        label: __`share-via-facebook`,
        url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
        icon: facebookIcon
      },
      {
        dataX: 'share-via-twitter',
        label: __`share-via-twitter`,
        url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
        icon: twitterIcon
      },
      {
        dataX: 'share-via-linkedin',
        label: __`share-via-linkedin`,
        url: `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodedUrl}&amp;title=${encodedTitle}&amp;summary=`,
        icon: linkedinIcon
      },
      {
        dataX: 'share-via-whatsapp',
        label: __`share-via-whatsapp`,
        url: `https://api.whatsapp.com/send?text=${encodedTitle} - ${encodedUrl}`,
        icon: whatsappIcon
      },
      {
        dataX: 'share-via-email',
        label: __`share-via-email`,
        url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}`,
        icon: emailIcon
      }
    ],
    [encodedUrl, encodedTitle, __]
  )

  const transitions = useTransition(expanded ? shareButtons : [], {
    key: (x) => x.url,
    trail: 20,
    from: { y: '-100%', opacity: 0, scale: 0 },
    enter: { y: '0%', opacity: 1, scale: 1 },
    leave: { y: '-100%', opacity: 0, scale: 0 }
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ButtonCircularGhost
        onClick={() => setExpanded(!expanded)}
        dataX='open-share-tray'
      >
        <Icon icon={expanded ? crossIcon : shareIcon} />
      </ButtonCircularGhost>

      <div className={styles.icons} aria-expanded={expanded}>
        {transitions((style, item) => (
          <animated.div {...{ style }}>
            <LinkButtonCircularPrimary
              href={item.url}
              target='_blank'
              dataX={item.dataX}
            >
              <Icon icon={item.icon} />
            </LinkButtonCircularPrimary>
          </animated.div>
        ))}
      </div>
    </div>
  )
}
